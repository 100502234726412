<template>
  <div class="home-wrapper" style="min-height: 670px;">
    <div class="banner">
      <div class="container mx-auto">
      <h1>{{ this.$store.getters.name }}，欢迎来到信奥课堂！</h1>
      </div>
    </div>
    <div class="course-list container mx-auto my-4">
      <el-row :gutter="20" v-if="list.length > 0">
        <el-col :span="6" v-for="item in list" :key="item.id">
          <div class="course-list-item" @click="goCourse(item.id)">
            <el-image class="w-full" style="height: 180px;" :src="item.cover" :alt="item.name" fit="cover"></el-image>
            <p class="py-2">{{ item.name }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import { list } from '@/api/course'

export default {
  name: 'Home',
  data() {
    return {
      list: []
    }
  },
  mounted() {
    list().then(res => {
      this.list = res.data.list
    })
  },
  methods: {
    goCourse(id) {
      this.$router.push({ path: '/course', query: { id }})
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 300px;
  background: url('https://haimakid-cdn.haimakid.net/xinao/banner-1.jpg') 50%/cover;
  z-index: -1;
}
.banner h1 {
  padding-top: 120px;
  color: #fff;
  font-size: 28px;
  text-align: left;
}
.course-list-item {
  border: 1px solid #ccc;
  box-sizing: border-box;
}
</style>